<template>
    <div>
		<a-row :gutter="24" type="flex">
			<a-col :span="24" class="mb-24">
            	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">

                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">Envio a SUNAT</h5>
                            </a-col>
                            <a-col :span="24" :md="12" class="d-flex align-items-center justify-content-end">
                                <a-button type="primary" @click="abrirModalTickets">Nuevo</a-button>
                            </a-col>
                        </a-row>
                    </template>

                    <TablaEnvioSunat  />
                </a-card>

			</a-col>
		</a-row>

                
        <a-modal v-model:visible="verModalTickets" :maskClosable="false" width="1000px" title="Seleccionar Tickets">
            <div class="px-4">
                <a-form ref="formFiltro" :model="envio.filters" layout="vertical">
                    <a-row :gutter="24">
                        <a-col :span="12" :md="8" :lg="6">
                            <a-form-item name="fecha_inicial" label="Fecha Inicial: ">
                                <a-date-picker v-model:value="envio.filters.fecha_inicial" format="YYYY-MM-DD" class="w-100" placeholder="Selecciona la Fecha"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12" :md="8" :lg="6">
                            <a-form-item name="fecha_final" label="Fecha Inicial: ">
                                <a-date-picker v-model:value="envio.filters.fecha_final" format="YYYY-MM-DD" class="w-100" placeholder="Selecciona la Fecha"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12" :md="8" :lg="6">
                            <a-form-item name="" label=" ">
                                <a-button type="primary" class="w-100" @click="filtrarDocumentos">Buscar</a-button>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <a-table
                :row-selection="rowSelection"
                :columns="columnas"
                :data-source="envio.documentosFiltrado"
                :row-key="record => record.id"
                :loading="isLoading"
                :pagination="{hideOnSinglePage: true}"
            >
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'estado_comercial'">
                        <span> {{record.estado_comercial == 'A' ? 'ACEPTADO' : record.estado_comercial == 'R' ? 'RECHAZADO' : 'PENDIENTE' }}</span>
                    </template>
                </template>
            </a-table>
            <template #footer>
                <a-button key="back" @click="cerrarModalTickets">Cerrar</a-button>
                <a-button key="submit" type="primary" :disabled="envio.documentosSeleccionado.length == 0" :loading="isSubmit" @click="enviarDocumentos" >Enviar</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import { defineComponent, reactive, computed, ref } from 'vue';
    import { useStore } from "vuex";

    import TablaEnvioSunat from '@/components/tenant/bandeja_entrada/TablaEnvioSunat';
    
    const columnas = [
        { title: 'EMPRESA', dataIndex: 'empresa_id', },
        { title: 'FECHA', dataIndex: 'fecha', },
        { title: 'SERIE', dataIndex: 'serie', },
        { title: 'NUMERO', dataIndex: 'correlativo', },
        { title: 'ESTADO', dataIndex: 'estado_comercial', },
        { title: 'MONTO CPE', dataIndex: 'total', align: 'right'},
    ];

    export default defineComponent({
        layout: {
            name: 'TenantLayout',
            props: {}
        },  

        components: { TablaEnvioSunat },

        setup() {
            const store = useStore();

            const state  = reactive({
                consulta : {
                    pag: {
                        total: 0,
                        current: 1,
                        pageSize: Number(process.env.VUE_APP_PAG_SIZE),                 
                    },
                    filters: {},
                    sorter: {},
                    data: [],
                },
            });

            const envio = reactive({
                documentos: [],
                documentosFiltrado: [],
                documentosSeleccionado: [],
                filters: {
                    fecha_inicial: null,
                    fecha_final: null,
                }
            });

            const verModalTickets = ref(false);
            const formFiltro = ref();

             // getters
            const isLoading = computed(() => store.getters["bandeja_entrada/isLoading"]);
            const isSubmit = computed(() => store.getters["envio_conformidad/isSubmit"]);
            const user = computed(() => store.getters["auth/user"]);

            // actions
            const obtenerTickets = data => {
                store.dispatch('envio_conformidad/obtenerTickets', data).then( 
                    response => { },
                    error  => {
                        let message = error.response ?  error.response.data.error : error.message;
                        AntN.error('Bandeja de entrada', message);
                    })
            }
            
            const abrirModalTickets = () => {
                store.dispatch('bandeja_entrada/obtenerDocumentosEnvio', {}).then( 
                    response => {
                        envio.documentos = response.data;
                        filtrarDocumentos();
                    },
                    error  => {
                        let message = error.response ?  error.response.data.error : error.message;
                        AntN.error('Bandeja de entrada', message);
                    })

                verModalTickets.value = true;
            }

            const cerrarModalTickets = () => {
                verModalTickets.value = false;
                formFiltro.value.resetFields();

            }
            
            const enviarDocumentos = () => {
                store.dispatch('envio_conformidad/enviarDocumentos', { ruc: user.value.ruc, documentos: envio.documentosSeleccionado}).then( 
                    response => {
                        debugger
                        cerrarModalTickets();
                        envio.documentos = [];
                    },
                    error  => {
                        let message = error.response ?  error.response.data.error : error.message;
                        AntN.error('Bandeja de entrada', message);
                    })
            }

            const filtrarDocumentos = () => {
                let { filters } = envio;

                let condiciones = '';

                if (filters.fecha_inicial && filters.fecha_final) {
                    let fecha_inicial = dayJS(filters.fecha_inicial).format('YYYY-MM-DD');
                    let fecha_final = dayJS(filters.fecha_final).format('YYYY-MM-DD');
                    
                    condiciones += ` AND fecha BETWEEN '${fecha_inicial}' AND '${fecha_final}'`;
                }

                let data = AlaSQL(`SELECT * FROM ? WHERE id NOT NULL ${condiciones}`, [envio.documentos]);
                envio.documentosFiltrado = data;
            }

            const rowSelection = {
                onChange: (selectedRowKeys, selectedRows) => {
                    envio.documentosSeleccionado = selectedRows;
                }
            };

            obtenerTickets(state.consulta);

            return { isLoading, isSubmit, formFiltro, envio, columnas, rowSelection, verModalTickets, abrirModalTickets, cerrarModalTickets, enviarDocumentos, filtrarDocumentos }
        }
    });
</script>