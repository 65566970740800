<template>
    <div>
        <a-divider orientation="left">Filtros</a-divider>

        <a-table
            :columns="columnas"
            :data-source="ticketsPaginado.data"
            :pagination="ticketsPaginado.pag"
            :row-key="record => record.id"
            :loading="isLoading"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'empresa'">
                    <span >{{ record.empresa.ruc }}</span>
                </template>
                <template v-if="column.dataIndex === 'estado'">
                    <span >{{ record.data_estado.descripcion }}</span>
                </template>
                <template v-if="column.dataIndex === 'acciones'">
                    <a-button type="primary" :loading="false">
                        <template #icon><font-awesome-icon :icon="['fas', 'location-arrow']" class="me-2"/> </template>
                        <span>
                            Enviar a SUNAT
                        </span>
                    </a-button>
                </template>
            </template>
        </a-table>
    </div>
</template>

<script>
    import { defineComponent, computed, reactive, ref } from 'vue';
    import { useStore } from "vuex";
    
    // import FiltradorTabla from '@/components/tenant/envio_conformidad/FiltradorTabla';

    const columnas = [
        { title: 'RUC EMPRESA', dataIndex: 'empresa', sorter: true, },
        { title: 'TICKET DOCUMENTO', dataIndex: 'ticket', sorter: true, },
        { title: 'NOMBRE ARCHIVO', dataIndex: 'nombre_archivo', },
        { title: 'FECHA EMISIÓN', dataIndex: 'fecha', sorter: true, },
        { title: 'ESTAD0', dataIndex: 'estado', sorter: true, },
        { title: 'ACCIONES', dataIndex: 'acciones', align: "right"},
    ];
    
    export default defineComponent({
        components: { },

        setup() {
            const store = useStore();

            const formState = reactive({
                documento: {},
            });

            const formRef = ref();

            // getters
            const isLoading = computed(() => store.getters["envio_conformidad/isLoading"]);
            const isDeleting = computed(() => store.getters["envio_conformidad/isDeleting"]);
            const ticketsPaginado = computed(() => store.getters["envio_conformidad/ticketsPaginado"]);

            // actions
            const handleTableChange = (pag, filter, sorter) => {
                let { data, filters } = ticketsPaginado.value;

                store.dispatch('envio_conformidad/obtenerTicketsPaginado', { data, pag, filters, sorter });
            };
            
            const filtrarTickets = (filters) => {
                let consulta = { ...ticketsPaginado.value };
                consulta.filters = filters;
                
                store.dispatch('envio_conformidad/obtenerTicketsPaginado', consulta );
            }

            return { formState, formRef, isLoading, columnas, handleTableChange, ticketsPaginado, filtrarTickets}
        }
    });
</script>